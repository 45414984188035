import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../../services/API';
import { toast } from 'react-toastify';
import { Form, Input } from '@rocketseat/unform';

export default function ModalEditar(props) {
  const [editando, setEditando] = useState(false);
  const [data, setData] = useState({});
  const [descricao, setDescricao] = useState('');
  const [nome, setNome] = useState('');
  const [codigo, setCodigo] = useState('');
  const [custoTotal, setCustoTotal] = useState('');
  const [tipo, setTipo] = useState('');
  const [categoria, setCategoria] = useState('');
  const [foto, setFoto] = useState(null);
  const [preview, setPreview] = useState(null);
  const [status, setStatus] = useState('Procurar...');

  const alterarFoto = async (e) => {
    if (e.target.files.length > 0) {
      setStatus(`${e.target.files[0].name}`);
      setFoto(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    } else {
      setStatus('Procurar...');
      setPreview(null);
    }
  };

  const atualizar = async (e) => {
    e.preventDefault();
    if (!nome) {
      toast.error('Informe o nome', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!codigo) {
      toast.error('Informe o código', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!descricao) {
      toast.error('Informe a descrição', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!custoTotal) {
      toast.error('Informe o custo total', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!tipo) {
      toast.error('Informe o tipo', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    setEditando(true);

    let formData = new FormData();
    formData.append('nome', nome);
    formData.append('descricao', descricao);
    formData.append('codigo', codigo);
    formData.append('custoTotal', custoTotal);
    formData.append('tipo', tipo);
    formData.append('categoria', categoria);
    formData.append('foto', foto);

    const resultado = await api.put(`/api/premios/${data.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (resultado) {
      toast.success('Prêmio atualizado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      props.onHide();
      props.atualizarPremio({
        ...{ nome, descricao, codigo, custoTotal, tipo, categoria },
        id: data.id,
      });
    }
    setEditando(false);
  };

  useEffect(() => {
    if (props.data) {
      if (!props.data.tipo) {
        props.data.tipo = 'geral';
      }
      setData(props.data);
      setNome(props.data.nome);
      setDescricao(props.data.descricao);
      setCodigo(props.data.codigo);
      setCustoTotal(props.data.custoTotal);
      setTipo(props.data.tipo);
      setCategoria(props.data.categoria || '');
      setPreview(props.data.diretorioFoto);
      setStatus('Procurar...');
    }
  }, [props]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-3 mb-3">
        <div className="d-flex flex-column align-items-center">
          <Form className="form" onSubmit={atualizar}>
            <label htmlFor="foto">Foto</label>
            <input
              type="file"
              id="foto"
              name="foto"
              style={{ display: 'none' }}
              onChange={alterarFoto}
            />
            <input
              className="text-left"
              id="botao"
              type="button"
              value={status}
              onClick={(e) => {
                document.getElementById('foto').click();
              }}
            />
            {preview && (
              <img alt="Preview" src={preview} className="mt-3 mb-3 w-100" />
            )}
            <label htmlFor="nome">Código</label>
            <Input
              id="codigo"
              name="codigo"
              type="text"
              placeholder="Código"
              value={codigo}
              onChange={(e) => setCodigo(e.target.value)}
            ></Input>
            <label htmlFor="nome">Nome</label>
            <Input
              id="nome"
              name="nome"
              type="text"
              placeholder="Nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            ></Input>
            <label htmlFor="descricao">Descrição</label>
            <textarea
              id="descricao"
              name="descricao"
              placeholder="Descrição"
              className="premio-desc p-2"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            ></textarea>
            <label htmlFor="custoTotal">Custo Total</label>
            <Input
              id="custoTotal"
              name="custoTotal"
              type="number"
              step="0.01"
              placeholder="Custo Total"
              value={custoTotal}
              onChange={(e) => setCustoTotal(e.target.value)}
            ></Input>
            <label htmlFor="tipo">Tipo</label>
            <select
              id="tipo"
              name="tipo"
              placeholder="Tipo"
              value={tipo}
              onChange={(e) => setTipo(e.target.value)}
            >
              <option disabled value="">
                Selecione o tipo
              </option>
              <option value="geral">Geral</option>
              <option value="bivolt">Bivolt</option>
              <option value="credito">Crédito</option>
              <option value="recarga">Recarga</option>
              <option value="pix">Pix</option>
            </select>
            <label htmlFor="categoria">Categoria</label>
            <Input
              id="categoria"
              name="categoria"
              type="text"
              placeholder="Categoria"
              value={categoria}
              onChange={(e) => setCategoria(e.target.value)}
            />
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {editando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
              disabled
            >
              SALVANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
            >
              SALVAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
